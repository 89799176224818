import { handleMessages } from './handle-messages';

export function startSubscriptionBoxCta() {
  document.addEventListener("DOMContentLoaded", function () {
    handleMessages(
      "subscription-box-cta",
      "dismiss-subscription-box-cta",
      "subscriptionBoxCtaDismissed"
    );
  });
}
